<template>
  <view-item title="投诉处理">
    <template #operation>
      <button-ajax v-if="form.data.id && form.data.status?.code === 'UI'" :method="operation.issue.click">下发定责</button-ajax>

      <button-ajax v-if="form.data.id && form.data.status?.code === 'UH'" :method="operation.handle.click">确认处理</button-ajax>

      <button-ajax v-if="form.data.id && form.data.status?.code === 'WF'" :method="operation.finish.click">解决完毕</button-ajax>

      <button-ajax v-if="form.data.id && form.data.status?.code !== 'UI'" :method="operation.rollback.click">退回</button-ajax>

      <button-ajax v-if="form.data.id && form.data.status?.code !== 'S'" :method="operation.submit.click">保存</button-ajax>

      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="detail">投诉信息</en-dropdown-item>
          <en-dropdown-item v-if="form.data.status?.code === 'UI'" command="delete">删除</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        code="CMTFD"
        :ajax="{ action: 'GET /enocloud/service/complaint/query' }"
        :props="{ start: 'complaintStartDate', end: 'complaintEndDate' }"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
        :ref="setRef('manifest')"
       :config="manifest.config"
      >
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <en-collapse>
          <en-collapse-item>
            <template #title>
              <div class="flex items-center gap-6">
                <span>{{ form.data.service?.vehicle?.plateNo }}</span>
                <span>{{ form.data.service?.vehicle?.owner.name }}/{{ form.data.service?.vehicle?.owner.cellphone }}</span>
                <span>工单号：{{ form.data.service?.serialNo }}</span>
                <span>{{ form.data.service?.vehicle?.vehicleSpec.join('/') }}</span>
                <en-tag>{{ form.data.status?.message }}</en-tag>
              </div>
            </template>
            <div class="grid grid-cols-3">
              <span>投诉编号：{{ form.data.serialNo }}</span>
              <span>投诉日期：{{ form.data.complaintDate }}</span>
              <span>回访人员：{{ form.data.visitor?.name }}</span>
            </div>
          </en-collapse-item>
        </en-collapse>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane label="历史维修项目" name="maintenance-history">
                <table-dynamic
                  code="SRDMTHF"
                  :height="height - 55"
                  :data="maintenance.data"
                  :method="maintenance.get"
                  :paging="maintenance.paging"
                  :loading="maintenance.loading"
                  pagination
                ></table-dynamic>
              </en-tab-pane>

              <en-tab-pane label="投诉信息" name="complaint">
                <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" class="grid grid-cols-3 gap-x-6">
                  <en-form-item label="投诉来源" prop="way">
                    <select-maintain
                      v-model="form.data.way"
                      :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['CMPLTBY']) }"
                      :props="{ label: 'name', value: 'name' }"
                      value-key="hint"
                      :disabled="form.disabled"
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="投诉对象" prop="targets">
                    <select-maintain
                      v-model="form.data.targets"
                      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['CPLTGT']) }"
                      :props="{ label: 'message', value: '' }"
                      value-key="code"
                      multiple
                      :disabled="form.disabled"
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="有效投诉" prop="valid.code">
                    <select-maintain
                      v-model="form.data.valid"
                      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['CPLVAL']) }"
                      :props="{ label: 'message', value: '' }"
                      value-key="code"
                      :disabled="form.disabled"
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="投诉内容" prop="complaintContent">
                    <en-input v-model="form.data.complaintContent" :disabled="form.disabled"></en-input>
                  </en-form-item>
                </en-form>
              </en-tab-pane>

              <en-tab-pane label="定责处理" name="issue" :disabled="form.data.status?.code === 'UI'">
                <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" class="grid grid-cols-3 gap-x-6">
                  <en-form-item label="服务经理">
                    <select-maintain
                      v-model="form.data.serviceManager"
                      :ajax="{ action: 'GET /enocloud/common/user', params: (params, name) => (params.payload = { name }) }"
                      :props="{ label: 'name', value: '' }"
                      value-key="id"
                      remote
                      :disabled="form.disabled"
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="分析原因">
                    <en-input v-model="form.data.cause" type="textarea" :disabled="form.disabled"></en-input>
                  </en-form-item>
                  <en-form-item label="确定责任人" prop="liablePersons">
                    <select-maintain
                      v-model="form.data.liablePersons"
                      :ajax="{ action: 'GET /enocloud/common/user', params: (params, name) => (params.payload = { name }) }"
                      :props="{ label: 'name', value: '' }"
                      value-key="id"
                      multiple
                      remote
                      :disabled="form.disabled"
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="责任人陈述经过" prop="liablePersonState">
                    <en-input v-model="form.data.liablePersonState" type="textarea" :disabled="form.disabled"></en-input>
                  </en-form-item>
                  <en-form-item label="处理意见">
                    <select-maintain
                      v-model="form.data.handlingSuggestions"
                      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['CPLHS']) }"
                      :props="{ label: 'message', value: '' }"
                      value-key="code"
                      multiple
                      :disabled="form.disabled"
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="罚款">
                    <en-input-number
                      v-model="form.data.individualCompensationAmount"
                      :step="5"
                      :disabled="form.disabled"
                      class="flex-1 mr-4"
                    ></en-input-number>
                    <span>元</span>
                  </en-form-item>
                  <en-form-item label="赔偿">
                    <en-input-number v-model="form.data.penaltyAmount" :step="5" :disabled="form.disabled" class="flex-1 mr-4"></en-input-number>
                    <span>元</span>
                  </en-form-item>
                  <en-form-item label="改善措施">
                    <select-maintain
                      v-model="form.data.improvements"
                      :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['CMPLTIMP']) }"
                      :props="{ label: 'name', value: 'name' }"
                      multiple
                      :disabled="form.disabled"
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="客户预约时间">
                    <en-date-picker
                      v-model="form.data.timeToInspect"
                      value-format="YYYY-MM-DDThh:mm:ss"
                      type="datetime"
                      :disabled="form.disabled"
                      class="w-full"
                    ></en-date-picker>
                  </en-form-item>
                </en-form>
              </en-tab-pane>

              <en-tab-pane label="投诉回访" name="visit" :disabled="form.data.status?.code === 'UI' || form.data.status?.code === 'UH'">
                <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" class="grid grid-cols-3 gap-x-6">
                  <en-form-item label="处理是否按期">
                    <select-maintain
                      v-model="form.data.processInTime"
                      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                      :props="{ label: 'message', value: '' }"
                      value-key="code"
                
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="现场回访满意度">
                    <select-maintain
                      v-model="form.data.onSiteVisitCSI"
                      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                      :props="{ label: (option: EnocloudCommonDefinitions['LookupDto']) => (option.code === 'Y' ? '满意' : '不满意'), value: 'code' }"
                      value-key="code"
                
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="原因">
                    <en-input v-model="form.data.dissatisfiedReasonOfOnSiteVisit" type="textarea" ></en-input>
                  </en-form-item>
                  <en-form-item label="客户再次回访结果">
                    <select-maintain
                      v-model="form.data.revisitCSI"
                      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                      :props="{ label: (option: EnocloudCommonDefinitions['LookupDto']) => (option.code === 'Y' ? '满意' : '不满意'), value: 'code' }"
                      value-key="code"
                
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="原因">
                    <en-input v-model="form.data.dissatisfiedReasonOfRevisit" type="textarea" ></en-input>
                  </en-form-item>
                </en-form>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <visit-complaint-dialog v-model="dialog.visible"></visit-complaint-dialog>

  <visit-complaint-detail v-model="detail.visible" :data="form.data" @confirm="detail.confirm"></visit-complaint-detail>
</template>

<script lang="ts">
import { EnMessageBox } from '@enocloud/components'
import VisitComplaintDialog from '@client/components/visit-complaint-dialog.vue'
import VisitComplaintDetail from '@client/components/visit-complaint-detail.vue'

export default factory({
  components: { VisitComplaintDialog, VisitComplaintDetail },

  config: {
    children: {
      operation: {
        submit: {
          async click() {
            await this.form.submit()
            return this.form.get()
          }
        },
        issue: {
          async click() {
            await this.form.issue()
            return this.form.get()
          }
        },
        handle: {
          async click() {
            await this.form.handle()
            return this.form.get()
          }
        },
        finish: {
          async click() {
            await this.form.finish()
            return this.form.get()
          }
        },
        rollback: {
          async click() {
            await this.form.rollback()
            return this.form.get()
          }
        },
        option: {
        async  command(option: string) {
            switch (option) {
              case 'detail':
                this.detail.visible = true
                break
              case 'delete':
              await EnMessageBox.confirm('是否确认删除单据', '提示')
                  await this.form.delete()
                  this.refs.manifest.table.method()
                  await this.form.get()
            }
          }
        }
      },
      manifest: {
        row: {
          async click(row: EnocloudServiceDefinitions['ComplaintDto']) {
            this.tabs.active = 'maintenance-history'
            this.form.data.id = row.id
            await this.form.get()
            this.maintenance.get()
          }
        },
        config:{
          OPERATION: { visible: false }
        }
      
      },
      tabs: {
        active: 'maintenance-history'
      },
      form: {
        rules: {
          way: [{ required: true, message: '请选择投诉来源' }],
          targets: [{ type: 'array', required: true, message: '请选择投诉对象' }],
          'valid.code': [{ required: true, message: '请选择有效投诉' }]
        },
        ajax: {
          get: {
            action: 'GET /enocloud/service/complaint/:complaintId',
            data: 'object',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          delete: {
            action: 'DELETE /enocloud/service/complaint/:complaintId',
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'PUT /enocloud/service/complaint',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          issue: {
            action: 'POST /enocloud/service/complaint/issue',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          handle: {
            action: 'POST /enocloud/service/complaint/handle',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          finish: {
            action: 'POST /enocloud/service/complaint/finish',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          rollback: {
            action: 'POST /enocloud/service/complaint/:complaintId/rollback',
            loading: true,
            params(params) {
              params.paths = [this.form.data?.id]
            }
          }
        },
        computed: {
          disabled() {
            return  this.form.data.status?.code === 'WF' || this.form.data.status?.code === 'S'
          }
        }
      },
      maintenance: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/:vehicleId/maintenance/history',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.paths = [this.form.data.service?.vehicle?.id]
            }
          }
        }
      },
      detail: {
        visible: false,
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      },
      dialog: {
        visible: false
      }
    }
  }
})
</script>
